import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice, formatAsPercentage } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';
import { SimpleText } from 'components/sanaText';
import PricePer from './PricePer';

const ProductPrice = ({ salesPrice, basePrice, isSpecialPrice, priceUnit, classNamePricePer = '' }) => {
  const currencyInfo = useCurrencyInfo();
  const { price, actionPrice, discountAmount, discountPercentage } = usePriceModel(salesPrice, basePrice, isSpecialPrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);

  const formattedActionPrice = currencyInfo && actionPrice
    && actionPrice !== price && formatAsPrice(actionPrice);

  const { texts: [
    priceLbl,
    actionPriceLbl,
    discountLbl,
    discountAmountTemplate,
    discountPercentTemplate,
  ], loaded } = useSanaTexts([
    'Price',
    'ActionPrice_Label',
    'ActionPrice_Discount_Label',
    'ActionPrice_DiscountAmount',
    'ActionPrice_DiscountPercentage',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  return (
    <>
      {(!!(formattedActionPrice || discountAmount || discountPercentage) ) && (
        <Col xs="auto" className="PLP_test price-block price-list" role="group">
          {!!actionPrice && (
            <div className={`${styles.actionPrice} action-price`} title={actionPriceLbl}>
              <i className="visually-hidden">{actionPriceLbl} {currencyInfo.symbol + actionPrice}</i>
              <span aria-hidden suppressHydrationWarning>
                {formattedActionPrice}
              </span>
            </div>
          )}
          {!!discountAmount && (
            <div className={`${styles.discountAmount} discount-amount`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {currencyInfo.symbol + discountAmount}</i>
              <span aria-hidden suppressHydrationWarning>
                {makeSimpleText(discountAmountTemplate, [formatAsPrice(discountAmount)])}
              </span>
            </div>
          )}
          {!!discountPercentage && (
            <div className={`${styles.discountPercentage} discount-percentage`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {discountPercentage + '%'}</i>
              <span aria-hidden>
                {!isSpecialPrice ? makeSimpleText(discountPercentTemplate, [formatAsPercentage(discountPercentage, currencyInfo, false, 2)]) : <SimpleText textKey="NetPrice" /> }
              </span>
            </div>
          )}
        </Col>
      )}
      <Col xs="auto" className={styles.priceBlock} title={priceLbl}>
        <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
        <div className="seprator">
          <Row className={styles.rowPricePer}>
            <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
              {formattedPrice}
            </span>
            {priceUnit && <PricePer priceUnit={priceUnit} className={classNamePricePer} />}
          </Row>
        </div>
      </Col>
    </>
  );
};

ProductPrice.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ProductPrice {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
