//195182_Phase-3_3.2.Packing_Information
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';

const UomPopup = ({ product }) => {
  const baseUom = product.baseUom && product.uoms && product.uoms.find(u => u.id.toUpperCase() === product.baseUom.toUpperCase())?.description;
  const minimumQuantity = product.uoms
    ?.filter(u => u.id.toUpperCase().indexOf(product.uom.id.toUpperCase()) !== -1)
    ?.map(f => f.minimumQuantity) ?? [];
  const uomDescription = product.uoms
    ?.filter(u => u.id.toUpperCase().indexOf(product.uom.id.toUpperCase()) !== -1)
    ?.map(f => f.description);

  const hasConvertableUOMS = product?.uoms?.some(uom => !!uom?.id && uom.id.toUpperCase() !== product?.baseUom?.toUpperCase());

  return (
    <div className="main-content">
      <div className="dropbox-popup">
        {minimumQuantity[0] != null && (
          <>
            <span>
              <SimpleText textKey={'UOM_MinimumQuantity'} />
            </span>
            <hr />
            <span className="min-quantity">{minimumQuantity} {uomDescription}</span>
          </>
        )}
        <div>
          {hasConvertableUOMS && (
            <>
              <span>
                <SimpleText textKey={'UOM_PackagingUnits'} />
              </span>
              <hr />
            </>
          )}
          <div className="stock-indicate">
            <table>
              <tbody>
                {product.uoms.map((uom, index) => {
                  if (!!uom?.id  && uom.id.toUpperCase() === product?.baseUom?.toUpperCase() && uom.multiplicationFactor <= 1)
                    return <tr key={index} />;
                  else
                    return (
                        <tr key={index}>
                          <td>1 {uom.description} = {uom.multiplicationFactor} {baseUom}</td>
                        </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

UomPopup.propTypes = {
  product: PropTypes.shape({
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
export default memo(UomPopup);
