//[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)

import styles from './Product.module.scss';
import selectStyles from 'components/primitives/form/Select.module.scss';
import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/primitives/form';
import { useSimpleTexts } from 'components/sanaText';

const FilterSelector = ({ filterId, filters, onFilterChange, className = '' }) => {
  const currentFilter = filters.find(u => u.selected) || {};
  const selectorId = `${filterId}_FILTER`,
    labelId = selectorId && selectorId + 'lb';

  const [allItems,] = useSimpleTexts(['ProductGroup_Filter_All',]).texts;
  const filterItems = [...[{ title: allItems, value: '' }], ...filters];
  const filterMap = filterItems.map((filter, index) => ({ name: filter.title, value: filter.value }));

  const onChange = useCallback(selectedValue => {
    onFilterChange(filterId, selectedValue);
  }, [filterId, onFilterChange]);

  return (
    <div className={`${className}`}>
      <Select
        items={filterMap}
        className={selectStyles.small}
        onChange={onChange}
        value={currentFilter.value}
        id={selectorId}
        labelId={labelId}
        optionClassName={styles.option}
        shouldReset={filterId}
      />
    </div>
  );
};

FilterSelector.propTypes = {
  filterId: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func,
  className: PropTypes.string,
};

export default memo(FilterSelector);
