import styles from './Product.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import UomTitle from './UomTitle';
import { Placeholder } from 'components/primitives/placeholders';

const PricePer = ({ uomId, uoms, priceUnit, needUom, className = '' }) => {

  let uom;

  if (needUom) {
    if (!uomId || !uoms)
      return null;

    uom = uoms.find(u => u.id === uomId);
    if (!uom || !uom.description)
      return null;
  }

  const pricePerPlaceholder = <Placeholder className={styles.pricePerPlaceholder} />;
  const uomTitlePlaceholder = <Placeholder className={styles.uomTitlePlaceholder} />;
  
  return (
    <span className={`${styles.pricePer} ${className} `}>
      <SimpleText textKey="Product_PricePerUnitOfMeasure" placeholder={pricePerPlaceholder} />
      {' '}
      {priceUnit && <span> {priceUnit} {' '}</span>}
      {needUom && <UomTitle id={uom.id} description={uom.description} placeholder={uomTitlePlaceholder} />}
    </span>
  );
};

PricePer.propTypes = {
  uomId: PropTypes.string,
  uoms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
  })),
  className: PropTypes.string,
  needUom: PropTypes.bool,
  priceUnit: PropTypes.number,
};

export default memo(PricePer);
